import { initializeIcons } from '@uifabric/icons';
import { Fabric, Spinner, SpinnerSize } from 'office-ui-fabric-react';
import React from 'react';
import AzureAD, {
  AuthenticationState,
  IAzureADFunctionProps
} from 'react-aad-msal';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch
} from 'react-router-dom';
import { About } from './components/About';
import NavBar from './components/NavBar';
import NavMenu from './components/NavMenu';
import TextMessageForm from './components/TextMessageForm';
import { ViewMessage } from './components/ViewMessage';
import { ViewMessages } from './components/ViewMessages';
import { authProvider } from './services/AuthProvider';

export default function App() {
  initializeIcons();

  return (
    <Fabric dir="ltr">
      <div className="border-t-solid border-t-8 border-blue-600">
        <div className="container mx-auto">
          <AzureAD provider={authProvider} forceLogin={true}>
            {(props: IAzureADFunctionProps) => {
              switch (props.authenticationState) {
                case AuthenticationState.Authenticated:
                  return (
                    <Router>
                      <nav>
                        <NavBar account={props.accountInfo?.account} />
                      </nav>
                      <main className="flex">
                        <div>
                          <NavMenu />
                        </div>
                        <div className="flex-grow">
                          <Switch>
                            <Route path="/send">
                              <TextMessageForm />
                            </Route>
                            <Route path="/view/:id">
                              <ViewMessage />
                            </Route>
                            <Route exact path="/view">
                              <ViewMessages />
                            </Route>
                            <Route path="/about">
                              <About />
                            </Route>
                            <Route path="/">
                              <Redirect to="/send" />
                            </Route>
                          </Switch>
                        </div>
                      </main>
                    </Router>
                  );
                case AuthenticationState.Unauthenticated:
                  return (
                    <div>
                      {props.error && (
                        <p>
                          <span>
                            An error occured during authentication, please try
                            again!
                          </span>
                        </p>
                      )}
                      <p>
                        <button onClick={props.login}>Login</button>
                      </p>
                    </div>
                  );
                case AuthenticationState.InProgress:
                  return (
                    <div className="flex flex-col justify-center items-center h-screen w-full">
                      <h1 className="text-3xl text-bold tracking-wide mb-8">
                        Tobii Dynavox Messenger...
                      </h1>
                      <Spinner
                        size={SpinnerSize.large}
                        label="Loading"
                        labelPosition="bottom"
                      />
                    </div>
                  );
              }
            }}
          </AzureAD>
        </div>
      </div>
    </Fabric>
  );
}
