import { Account } from 'msal';
import React from 'react';
import { Persona } from './Persona';

export interface NavBarProps {
  account: Account | undefined;
}

export default function NavBar(props: NavBarProps) {
  return (
    <div className="flex justify-between mt-2 mb-4 items-end">
      <div>
        <h1 className="text-3xl font-medium tracking-wide">Messenger</h1>
      </div>
      <div>{props.account ? <Persona account={props.account} /> : <></>}</div>
    </div>
  );
}
