import React from 'react';
import { Link } from 'react-router-dom';

export function About() {
  return (
    <div className="w-1/2">
      <h1 className="text-lg tracking-wide mb-8">Tobii Dynavox Messenger</h1>

      <p className="text-base mb-4">
        Welcome to Tobii Dynavox Messenger. This is a pilot application that
        allows you to send text messages to Tobii Dynavox customers from online.
      </p>
      <p className="text-base mb-4">
        On the{' '}
        <Link className="text-blue-500 hover:underline" to="/send">
          Send Message
        </Link>{' '}
        page, you can send a message. All you need is the phone number and body
        of the message you'd like to send. We're limiting messages to US numbers
        only and 160 characters for now. We also have some common messages that
        you can prefill the body field with, to save time!
      </p>
      <p className="text-base mb-12">
        On the{' '}
        <Link className="text-blue-500 hover:underline" to="/view">
          View Messages
        </Link>{' '}
        page, you can view all the messages that have been sent. If you click on
        a message, you can view specific details for it.
      </p>
      <p className="text-xs">
        If you have any problems or questions, please reach out to Edward
        Sanders via Teams (preferred) or Outlook.
      </p>
    </div>
  );
}
