import { User } from '@microsoft/microsoft-graph-types';
import {
  DefaultButton,
  Icon,
  MaskedTextField,
  PrimaryButton,
  Spinner,
  SpinnerSize,
  Stack,
  TextField
} from 'office-ui-fabric-react';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { TextMessageRequest } from '../interfaces/TextMessageRequest';
import { TextMessageResponse } from '../interfaces/TextMessageResponse';
import { getMe } from '../services/Graph';
import { sendTextMessage } from '../services/Messenger';

export default function TextMessageForm() {
  //predefined message templates
  const messageTemplates = [
    'Tobii Dynavox\nYour Speech Device is waiting to be shipped!\nPlease call (XXX) XXX-XXXX or email XXXXX@tobiidynavox.com for more info.',
    "This is <NAME> from Tobii Dynavox. Your speech device is ready to ship! We'll be calling you shortly to verify the shipping info.",
    'Tobii Dynavox\nSorry we missed you. Please give us a call at (XXX) XXX-XXXX or email us at XXXXX@tobiidynavox.com.',
    "This is <NAME> from Tobii Dynavox. We're having trouble getting the paperwork from your doctor. Please call me at (XXX) XXX-XXXX.",
    'This is <NAME> from Tobii Dynavox. We requested some information from your SLP but have not yet received it. Please call me at (XXX) XXX-XXXX.',
    'This is <NAME> from Tobii Dynavox. I am working on your speech device but need some additional information from you. Please call me at (XXX) XXX-XXXX.',
    'This is <NAME> from Tobii Dynavox, I am working on your speech device but need some additional paperwork from your. Please call me at (XXX) XXX-XXXX.'
  ];

  //predefined maximum body length
  const maxBodyLength = 160;

  //predefined default body value
  const defaultBody = 'Hello, this is Tobii Dynavox.\n';

  const [user, setUser] = useState<User>();
  const [number, setNumber] = useState('');
  const [body, setBody] = useState(defaultBody);
  const [bodyLengthClassName, setBodyLengthClassName] = useState('');
  const [isNumberValid, setIsNumberValid] = useState(false);
  const [isBodyValid, setIsBodyValid] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isMessageSending, setIsMessageSending] = useState(false);
  const [messageSent, setMessageSent] = useState(false);
  const [lastSentMessage, setLastSentMessage] = useState<TextMessageResponse>();
  const [hasPrefilledTemplates, setHasPrefilledTemplates] = useState(false);

  useEffect(() => {
    async function getUser() {
      const user = await getMe();
      setUser(user);
      setHasPrefilledTemplates(true);
    }

    getUser();
  }, []);

  useEffect(() => {
    const newBodyLengthState = getBodyLengthState(body.length);
    const newBodyLengthClassName = getBodyLengthClassName(newBodyLengthState);
    setBodyLengthClassName(newBodyLengthClassName);
  }, [body]);

  useEffect(() => {
    setIsFormValid(isNumberValid && isBodyValid);
  }, [isNumberValid, isBodyValid]);

  const onSendButtonClicked = async (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();

    setIsMessageSending(true);

    const message: TextMessageRequest = {
      to: `+1${number}`,
      body: body
    };

    const sentMessage = await sendTextMessage(message);

    setMessageSent(true);
    setLastSentMessage(sentMessage);
    setNumber('');
    setBody('');
    setIsMessageSending(false);

    setTimeout(() => {
      setMessageSent(false);
      setLastSentMessage(undefined);
    }, 10000);
  };

  const clearBody = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setBody('');
  };

  const formatTemplate = (template: string) => {
    let formattedTemplate = template;

    if (user?.displayName) {
      formattedTemplate = formattedTemplate.replace('<NAME>', user.displayName);
    }

    if (user?.mail) {
      formattedTemplate = formattedTemplate.replace(
        'XXXXX@tobiidynavox.com',
        user.mail.toLowerCase()
      );
    }

    if (user?.businessPhones?.length === 1) {
      //phone numbers from the graph API have inconsistent formatting, so we must sanitize it before formatting
      let phoneNumber = user.businessPhones[0]
        .replace('(', '')
        .replace(')', '')
        .replace('-', '')
        .replace(' ', '');

      const phoneNumberSegments = [
        phoneNumber.substring(0, 3),
        phoneNumber.substring(3, 6),
        phoneNumber.substring(6)
      ];

      formattedTemplate = formattedTemplate.replace(
        '(XXX) XXX-XXXX',
        `(${phoneNumberSegments[0]}) ${phoneNumberSegments[1]}-${phoneNumberSegments[2]}`
      );
    }

    return formattedTemplate;
  };

  const getBodyLengthState = (length: number) => {
    if (length > maxBodyLength) {
      return 'overLimit';
    } else if (length > (maxBodyLength / 8) * 7) {
      return 'dangerZone';
    } else if (length > (maxBodyLength / 4) * 3) {
      return 'gettingClose';
    } else {
      return 'good';
    }
  };

  const getBodyLengthClassName = (newState: string) => {
    switch (newState) {
      case 'overLimit':
        return 'text-red-700';
      case 'dangerZone':
        return 'text-orange-600';
      case 'gettingClose':
        return 'text-yellow-600';
      case 'good':
        return 'text-green-700';
      default:
        return '';
    }
  };

  const validateNumber = (value: string): string => {
    const normalizedNumber = normalizeNumber(value);

    if (normalizedNumber.length !== 10) {
      setIsNumberValid(false);
      return '';
    }

    for (let char of normalizedNumber) {
      if (Number(char) === undefined) {
        setIsNumberValid(false);
        return '';
      }
    }

    setIsNumberValid(true);
    return '';
  };

  const validateBody = (value: string): string => {
    if (body.length < 1 || body.length > maxBodyLength) {
      setIsBodyValid(false);
      return '';
    } else {
      setIsBodyValid(true);
      return '';
    }
  };

  const normalizeNumber = (number: string) => {
    return number
      .trim()
      .replace(/-/g, '')
      .replace(/\(/g, '')
      .replace(/\)/g, '')
      .replace(/ /g, '')
      .replace(/_/g, '');
  };

  return (
    <div className="flex">
      <form className="w-72 mr-20">
        <Stack tokens={{ childrenGap: 10 }}>
          <Stack.Item>
            <MaskedTextField
              prefix="+1"
              className="font-mono"
              label="Phone Number"
              type="tel"
              mask="(999) 999-9999"
              value={number}
              onChange={(e, v) => (v ? setNumber(v) : setNumber(''))}
              onGetErrorMessage={validateNumber}
            />
          </Stack.Item>
          <Stack.Item>
            <TextField
              label="Message"
              multiline
              autoAdjustHeight
              value={body}
              onChange={(e, v) => (v ? setBody(v) : setBody(''))}
              onGetErrorMessage={validateBody}
            />
            <p
              className={`text-xs float-right transition-colors duration-100 ease-linear ${bodyLengthClassName}`}
            >
              {body.length} / {maxBodyLength}
            </p>
            <button
              onClick={clearBody}
              className={`mr-4 text-xs float-right text-gray-600 hover:underline`}
            >
              Clear
            </button>
          </Stack.Item>
          <Stack.Item>
            <div className="flex items-center mt-2">
              <PrimaryButton
                className="mr-8"
                text="Send"
                type="submit"
                onClick={onSendButtonClicked}
                disabled={!isFormValid}
                iconProps={{ iconName: 'Send' }}
              />
              {isMessageSending ? (
                <Spinner
                  hidden={!isMessageSending}
                  label="Sending message"
                  labelPosition="right"
                />
              ) : (
                <>
                  {messageSent ? (
                    <div className="flex flex-grow items-center">
                      <div className="flex items-center justify-center w-6 h-6 bg-green-500 rounded-full mr-2">
                        <Icon
                          className="text-white text-baseline"
                          iconName="CheckMark"
                        />
                      </div>
                      <div>
                        <p className="text-sm">Message Sent!</p>
                        <Link
                          className="text-xs text-blue-500 hover:underline"
                          to={`view/${lastSentMessage?.id}`}
                        >
                          View Message #{lastSentMessage?.id}
                        </Link>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </div>
          </Stack.Item>
        </Stack>
      </form>
      {hasPrefilledTemplates ? (
        <div>
          <div className="mb-8">
            <h1 className="text-xl tracking-wide mb-4">Templates</h1>
            <p className="text-xs mb-2">Here are some useful templates.</p>
            <p className="text-xs">
              We went ahead and prefilled them with your info for convenience.
            </p>
          </div>
          {messageTemplates.map((template, index) => {
            const formattedTemplate = formatTemplate(template);
            return (
              <div className="flex flex-column mb-4" key={index}>
                <DefaultButton
                  onClick={() => setBody(formattedTemplate)}
                  text="Use"
                  className="self-start mr-4"
                />
                <div>
                  {formattedTemplate.split('\n').map((segment, index) => (
                    <p className="mb-2" key={index}>
                      {segment}
                    </p>
                  ))}
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div className="flex justify-center items-center mx-auto">
          <Spinner size={SpinnerSize.large} label="Loading templates" />
        </div>
      )}
    </div>
  );
}
