import React from 'react';
import { Link, useLocation } from 'react-router-dom';

export default function NavMenu() {
  const links = [
    { pathname: '/send', text: 'Send Message' },
    { pathname: '/view', text: 'View Messages' },
    { pathname: '/about', text: 'About' }
  ];

  const location = useLocation();

  return (
    <nav className="sticky w-48 flex flex-col">
      {links.map((link) => (
        <Link
          to={link.pathname}
          className={`${
            location.pathname === link.pathname
              ? 'border-blue-600 font-semibold'
              : ''
          } text-base pl-2 pt-2 pb-2 border-solid border-white hover:border-blue-600 border-l-4`}
        >
          {link.text}
        </Link>
      ))}
    </nav>
  );
}
