import { Account } from 'msal';
import { Persona as FabricPerson, PersonaSize } from 'office-ui-fabric-react';
import React, { useEffect, useState } from 'react';
import { getMyAvatar } from '../services/Graph';

export interface PersonaProps {
  account: Account | undefined;
}

export function Persona(props: PersonaProps) {
  const [avatarUrl, setAvatarUrl] = useState('');

  //retrieve the logged in avatar URL on first render
  useEffect(() => {
    async function getAvatarUrl() {
      const blob = await getMyAvatar();
      const url = URL.createObjectURL(blob);
      setAvatarUrl(url);
    }

    getAvatarUrl();
  }, []);

  return (
    <FabricPerson
      imageUrl={avatarUrl}
      text={props.account?.name}
      size={PersonaSize.size32}
    />
  );
}
