import { format } from 'date-fns';
import {
  DetailsList,
  IColumn,
  SelectionMode,
  Spinner,
  SpinnerSize
} from 'office-ui-fabric-react';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getTextMessages } from '../services/Messenger';

export interface DetailsListMessage {
  id: number;
  to: string;
  sentBy: string;
  dateSent: string;
  body: string;
}

export function ViewMessages() {
  //predefined columns
  const columns: IColumn[] = [
    {
      key: 'idColumn',
      name: 'Id',
      minWidth: 16,
      maxWidth: 30,
      fieldName: 'id'
    },
    {
      key: 'toColumn',
      name: 'To',
      minWidth: 100,
      maxWidth: 100,
      fieldName: 'to'
    },
    {
      key: 'sentBy',
      name: 'Sent By',
      minWidth: 50,
      maxWidth: 150,
      fieldName: 'sentBy'
    },
    {
      key: 'dateSentColumn',
      name: 'Date Sent',
      minWidth: 100,
      maxWidth: 100,
      fieldName: 'dateSent'
    },
    {
      key: 'bodyColumn',
      name: 'Body',
      minWidth: 100,
      fieldName: 'body'
    }
  ];

  //need the history to push new page on row click
  const history = useHistory();

  const [messages, setMessages] = useState<DetailsListMessage[]>([]);

  //retrieve messages on load
  useEffect(() => {
    async function getMessages() {
      const firstMessages = await getTextMessages();

      const messagesForDetailsList: DetailsListMessage[] = firstMessages.map(
        (message) => {
          return {
            id: message.id,
            to: message.to,
            sentBy: message.userDisplayName,
            dateSent: format(new Date(message.dateSent), 'MM/dd/yyyy'),
            body: message.body
          };
        }
      );

      setMessages(messagesForDetailsList);
    }

    getMessages();
  }, []);

  if (messages) {
    return (
      <DetailsList
        className="cursor-pointer"
        items={messages}
        columns={columns}
        selectionMode={SelectionMode.none}
        onItemInvoked={(item) => history.push(`/view/${item.id}`)}
        onActiveItemChanged={(item) => history.push(`/view/${item.id}`)}
      />
    );
  } else {
    return (
      <div className="flex justify-center items-center h-64">
        <Spinner size={SpinnerSize.large} label="" />
      </div>
    );
  }
}
