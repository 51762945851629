import { format } from 'date-fns';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { TextMessageResponse } from '../interfaces/TextMessageResponse';
import { getTextMessage } from '../services/Messenger';

export function ViewMessage() {
  const { id } = useParams();
  const [message, setMessage] = useState<TextMessageResponse>();

  useEffect(() => {
    async function getMessage() {
      const newMessage = await getTextMessage(Number(id));
      setMessage(newMessage);
    }

    getMessage();
  }, [id]);

  if (message) {
    return (
      <div className="w-64">
        <h1 className="text-xl mb-4">Message #{message.id}</h1>
        <dl>
          <div className="mb-4">
            <dt className="w-24 mb-1 text-base font-bold">To</dt>
            <dd className="text-base">{message.message.to}</dd>
          </div>
          <div className="mb-4">
            <dt className="w-24 mb-1 text-base font-bold">Sent By</dt>
            <dd className="text-base">{message.user.displayName}</dd>
          </div>
          <div className="mb-4">
            <dt className="w-24 mb-1 text-base font-bold">Date Sent</dt>
            <dd className="text-base">
              {format(new Date(message.message.date_sent), 'MM/dd/yyyy')}
            </dd>
          </div>
          <div>
            <dt className="w-24 mb-1 text-base font-bold">Body</dt>
            <dd className="text-base">{message.message.body}</dd>
          </div>
        </dl>
      </div>
    );
  } else {
    return (
      <div className="flex justify-center items-center h-64">
        <Spinner size={SpinnerSize.large} label="" />
      </div>
    );
  }
}
