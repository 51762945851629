import { AuthenticationParameters, Configuration } from 'msal';
import {
  IMsalAuthProviderConfig,
  LoginType,
  MsalAuthProvider
} from 'react-aad-msal';

const config: Configuration = {
  //because they are only identifiers, we don't need to store the Client or Tenant Ids as secrets
  auth: {
    authority:
      'https://login.microsoftonline.com/f253f952-50bd-4884-bd3b-56ba582a9e42', 
    clientId: '9d9c9c3c-e3c6-4e34-94cc-03345915ea9c',
    postLogoutRedirectUri: 'https://messenger.tobiidynavox.com',
    redirectUri: 'https://messenger.tobiidynavox.com',
    validateAuthority: true,
    navigateToLoginRequestUrl: true
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true
  }
};

const defaultAuthenticationParameters: AuthenticationParameters = {
  scopes: ['api://9d9c9c3c-e3c6-4e34-94cc-03345915ea9c/user_impersonation']
};

const options: IMsalAuthProviderConfig = {
  loginType: LoginType.Redirect,
  tokenRefreshUri: 'https://messenger.tobiidynavox.com/auth.html'
};

export const authProvider = new MsalAuthProvider(
  config,
  defaultAuthenticationParameters,
  options
);

export async function getAccessToken(scopes?: string[]) {
  let authenticationParameters = scopes
    ? { scopes: scopes }
    : defaultAuthenticationParameters;

  try {
    const token = await authProvider.acquireTokenSilent(
      authenticationParameters
    );
    return token.accessToken;
  } catch (silentTokenError) {
    try {
      const token = await authProvider.acquireTokenPopup(
        authenticationParameters
      );
      return token.accessToken;
    } catch (popupTokenError) {
      return popupTokenError;
    }
  }
}
