import {
  AuthProvider,
  AuthProviderCallback,
  Client,
  Options
} from '@microsoft/microsoft-graph-client';
import { User } from '@microsoft/microsoft-graph-types';
import { getAccessToken } from './AuthProvider';

export async function getMe(): Promise<User> {
  return (await buildClient()).api('/me').get();
}

export async function getMyAvatar() {
  return (await buildClient()).api('/me/photo/$value').get();
}

export async function getUser(id: string): Promise<User> {
  return (await buildClient()).api(`/users/${id}`).get();
}

export function getUserAvatar(id: string) {}

async function buildClient() {
  const authProvider: AuthProvider = async (callback: AuthProviderCallback) => {
    // Your logic for getting and refreshing accessToken
    const accessToken = await getAccessToken([
      'user.read',
      'user.readbasic.all'
    ]);

    const error = typeof accessToken === 'string' ? undefined : accessToken;
    // Error should be passed in case of error while authenticating
    // accessToken should be passed upon successful authentication
    callback(error, accessToken);
  };

  let options: Options = {
    authProvider
  };

  return Client.init(options);
}
