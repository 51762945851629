import { TextMessage } from '../interfaces/TextMessage';
import { TextMessageRequest } from '../interfaces/TextMessageRequest';
import { TextMessageResponse } from '../interfaces/TextMessageResponse';
import { getAccessToken } from './AuthProvider';

const baseApiUrl = 'https://tdmessenger.azurewebsites.net';

// uncomment below and comment above if you're using the development API server
// const baseApiUrl = 'https://localhost:5001';

const baseClientUrl = 'https://messenger.tobiidynavox.com';

// uncomment below and comment above if you want to test CORS from the development server
// const baseClientUrl = 'http://localhost:3000';

export async function sendTextMessage(
  message: TextMessageRequest
): Promise<TextMessageResponse | undefined> {
  const authorizationHeader = `Bearer ${await getAccessToken()}`;

  //if the user can't authenticate, don't issue the request
  if (!authorizationHeader) {
    return undefined;
  }

  const response = await fetch(`${baseApiUrl}/textMessages`, {
    method: 'POST',
    body: JSON.stringify(message),
    headers: {
      'Access-Control-Allow-Origin': baseClientUrl,
      Authorization: authorizationHeader,
      'Content-Type': 'application/json'
    }
  });

  if (response.ok) {
    const data = await response.json();
    return data;
  } else {
    console.error('Could not create message');
  }
}

export async function getTextMessages(userId?: string): Promise<TextMessage[]> {
  const token = await getAccessToken();

  const authorizationHeader = `Bearer ${token}`;

  //if the user can't authenticate, don't issue the request
  if (!authorizationHeader) {
    return [];
  }

  const requestString = userId
    ? `${baseApiUrl}/textMessages?userId=${userId}`
    : `${baseApiUrl}/textMessages`;

  const response = await fetch(requestString, {
    method: 'GET',
    headers: {
      Authorization: authorizationHeader,
      'Access-Control-Allow-Origin': baseClientUrl
    }
  });

  if (response.ok) {
    const data = await response.json();
    return data;
  } else {
    console.error("Couldn't retrieve messages");
    return [];
  }
}

export async function getUserIds() {
  const authorizationHeader = `Bearer ${await getAccessToken()}`;

  //if the user can't authenticate, don't issue the request
  if (!authorizationHeader) {
    return [];
  }

  const response = await fetch(`${baseApiUrl}/userIds`, {
    method: 'GET',
    headers: {
      Authorization: authorizationHeader,
      'Access-Control-Allow-Origin': baseClientUrl
    }
  });

  if (response.ok) {
    const data = await response.json();
    return data;
  } else {
    console.error("Couldn't retrieve messages");
    return [];
  }
}

export async function getTextMessage(
  id: number
): Promise<TextMessageResponse | undefined> {
  const authorizationHeader = `Bearer ${await getAccessToken()}`;

  //if the user can't authenticate, don't issue the request
  if (!authorizationHeader) {
    return undefined;
  }

  const response = await fetch(`${baseApiUrl}/textMessages/${id}`, {
    method: 'GET',
    headers: {
      Authorization: authorizationHeader,
      'Access-Control-Allow-Origin': baseClientUrl
    }
  });

  if (response.ok) {
    return await response.json();
  } else {
    console.error("Couldn't retrieve message");
  }
}
